<template>
    <div class="medialist">
         <a-page-header
            :title="$t('medialist.title')"
            class="header_title"
        />
        <a-spin :spinning="spinning">
        <div class="box_content">
              <a-button class="ant-btn-danger" @click="addMediaBtn"  type="primary">
                  {{$t('medialist.btn_add')}}
              </a-button>
              <div style="height:20px;width:100%;"></div>
              <!-- <h4>Brand introduction</h4> -->

            <a-tabs :active-key="activeKey" @change="callback" tabPosition="left">

                <a-tab-pane  v-for="item in media_types" :key="item.media_type_id" :tab="item.name">
                  <a-list :grid="{ gutter: 6, column: 6 }" :data-source="mediaData">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                      <a-card>
                        <div>{{item.asset}}</div>
                        <img @click="editBtn(item.media_id)" class="mediaimg" v-lazy="`${item.img}`" alt="error" />
                        <div @click="deleteBtn(item.media_id,index)" class="delmediabtn">Delete</div>
                      </a-card>
                    </a-list-item>
                  </a-list>
                  <a-pagination v-if="mediaData.length>0" @change="changePage(item.media_type_id,page, pageSize)" v-model="current" :pageSize="10" :total="total_rows" show-less-items />
                </a-tab-pane>
                </a-tabs>

            <!-- <a-list :grid="{ gutter: 16, column: 6 }" :data-source="mediaData">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-card>
                  <div>{{item.asset}}</div>
                  <img @click="editBtn(item.media_id)" class="mediaimg" v-lazy="`${item.img}`" alt="error" />
                  <div @click="deleteBtn(item.media_id,index)" class="delmediabtn">Delete</div>
                </a-card>
              </a-list-item>
            </a-list> -->

            <!-- <div style="height:20px;width:100%;border-top: 1px solid #ccc;"></div>
            <h4>Product video</h4>
            <a-list :grid="{ gutter: 16, column: 4 }" :data-source="mediaData">
              <a-list-item slot="renderItem" slot-scope="item, index" v-if="item.media_type_id == 2">
                <a-card >
                  <div>{{item.asset}}</div>
                  <img @click="editBtn(item.media_id)" class="mediaimg"   v-lazy="`${item.img}`" alt="error" />
                  <div @click="deleteBtn(item.media_id,index)" class="delmediabtn">Delete</div>
                </a-card>
              </a-list-item>
            </a-list> -->
            </div>
        </a-spin>
    </div>

</template>

<script>

import {getMedias,deleteMedia,getMediaTypes} from '@/network/mediarequest'
export default {
    name: 'MediaList',
    components: {  },
    directives: {  },
    data() {
        return {
          activeKey:'',
          spinning: false,
          delayTime: 500,
          loading: false,
          imageUrl: '',
          mediaData:[],
          current:1,
          total_rows:0,
          media_types:[],
          media_type_id:''
        };
    },
    created() {
      getMediaTypes().then(res=>{
          if(res.header.code==200){
            // console.log(res.body.media_types);
            this.media_types=res.body.media_types;

            if(!this.$route.query.media_type_id){
              this.media_type_id = res.body.media_types[0].media_type_id;
            }else{
              this.media_type_id = this.$route.query.media_type_id;
            }
            this.getMediasData(1,this.media_type_id);
          }
      })
    },
    methods: {
        getMediasData(page,media_type_id){
          this.spinning = true;
          // let media_type_id = this.media_type_id;
          // console.log(media_type_id)
          this.activeKey = media_type_id;
          getMedias(page,media_type_id)
            .then(res=>{
              if(res.header.code==200){
                // console.log(this.total_rows);
                this.total_rows=res.body.total_rows;
                this.mediaData =res.body.medias;
              }
            })
          this.spinning = false;
        },
        addMediaBtn(){
          this.$router.push('/media/editmedia')
        },
        editBtn(e){
          let media_type_id = this.media_type_id;
          this.$router.push({path:'/media/editmedia',query:{media_id:e,media_type_id:media_type_id}})
        },
        deleteBtn(e,index){
          deleteMedia(e)
          .then(res=>{
             if(res.header.code==200){
                this.$message.success("deleted!")
                this.mediaData.splice(index,1)
                return ;
          }})
        },
        changePage(media_type_id,current){
          // console.log(media_type_id);
          // console.log(current);
          this.current = current;
          this.media_type_id = media_type_id;
          this.getMediasData(current,media_type_id);
        },
        callback(key) {
            console.log(key);
          this.media_type_id = key;
          this.activeKey = key;
          this.getMediasData(1,key);
        },
    },
};
</script>

<style  scoped>





.mediaimg{width: 100%;height: 100%;}
.delmediabtn{text-align: center;color: #fff; padding: 5px;background-color: rgb(190, 103, 103); }

</style>